import React, { useState, useEffect, useRef } from 'react'
import '../StyleSheets/Dashboard.css'
import TarsServices from '../Services/TarsServices'
import Swal from 'sweetalert2'
import { useCreditRequestAuth } from "../Context/useCreditRequestAuth";
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useCreditRequest } from '../Context/useCreditRequest';
import { Accordion, Badge, Button, Card, Col, Container, ListGroup, Modal, OverlayTrigger, Row, Tooltip, Spinner } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ReactStars from 'react-stars'
import MaskedInput from "react-text-mask";
import { createNumberMask } from "text-mask-addons";
import dayjs from 'dayjs';
import OlimpiaServices from "../Services/OlimpiaServices";
import ViewSDKClient from './ViewSDKClient'
import EfectyImg from '../Images/EfectyImg.png';
import PSEImg from '../Images/BotonPSE-01.png';
import PPAlert from '../Images/PPAlert.svg'
import NequiImg from '../Images/nequi.png'
import QRCode from "react-qr-code";


function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    //   integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const currencyMask = createNumberMask(defaultMaskOptions)


const PdfViewer = ({ base64, id, fileName }) => {


    useEffect(() => {
        var byteCharacters = atob(base64);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);

        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {

            viewSDKClient.previewFile(
                id,
                {
                    showAnnotationTools: true,
                    showLeftHandPanel: true,
                    showPageControls: true,
                    showDownloadPDF: true,
                    showPrintPDF: true
                },
                fileURL,
                fileName
            );

        });
    }, []);

    return <div style={{ width: "100%", height: "600px" }} id={id}></div>
}

const WompiButton = ({ data }) => {

    const formRef = useRef(null)

    useEffect(() => {
        const script = document.createElement('script');



        if (script.readyState) {  // only required for IE <9
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;

                    const iframes = document.getElementsByClassName("waybox-iframe")
                    for (let element of iframes) {
                        element.setAttribute("scrolling", "yes");
                    };

                }
            };
        } else {  //Others
            script.onload = function () {
                const iframes = document.getElementsByClassName("waybox-iframe")
                for (let element of iframes) {
                    element.setAttribute("scrolling", "yes");
                };
            };
        }



        script.src = "https://checkout.wompi.co/widget.js";
        script.setAttribute("data-render", "button")
        script.setAttribute("data-public-key", process.env.REACT_APP_WOMPI_PUBLIC_KEY)
        script.setAttribute("data-currency", "COP")
        script.setAttribute("data-amount-in-cents", data.AmountInCents)
        script.setAttribute("data-reference", data.PaymentReference)
        script.setAttribute("data-signature:integrity", data.EncryptedString)
        script.setAttribute("data-redirect-url", process.env.REACT_APP_URL_PAYMENT_REDIRECT)

        formRef.current.appendChild(script);
    }, []);

    return <form ref={formRef} >
    </form>
}

const Dashboard = () => {
    const navigate = useNavigate();
    const { CalculateCreditLimit, GetInvitations, GetApplicationsByCustomer, GetOriginattionsByCustomer, CustomPaymentAmount, GetBankList, GetPSEUrl, CreateNequiPayment, GetNequiPayment } = TarsServices();
    const [creditLimit, setCreditLimit] = useState(null);
    const [expirationCreditLimit, setExpirationCreditLimit] = useState("");
    const [invitations, setInvitations] = useState([]);
    const [credits, setCredits] = useState([]);
    const [creditData, setcreditData] = useState(null);

    const [applications, setApplications] = useState([]);
    const [originations, setOriginations] = useState([]);
    const [modalCredit, setModalCredit] = useState(null);

    const [expandPenaltyCredits, setExpandPenaltyCredits] = useState(false);
    const [expandCredits, setExpandCredits] = useState(false);
    const [expandInvitations, setExpandInvitations] = useState(false);

    const [sending, setSending] = useState(false);
    const [retryButton, setRetryButton] = useState(false);
    const [updateProfile, setUpdateProfile] = useState(false);
    let moneyLocale = Intl.NumberFormat('es-CO');
    const { getStorage, setStorage, logout, clearRequest } = useCreditRequestAuth();
    const { setLogoSize } = useCreditRequest();
    const MySwal = withReactContent(Swal);
    let request = getStorage("request")
    // const tooltipRef = useRef(null);
    // const [showTooltip1, setShowTooltip1] = useState(false);
    const [showModalFB, setShowModalFB] = useState(false);

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [selectedReference, setSelectedReference] = useState({});
    const [selectedMinimumPayment, setSelectedMinimumPayment] = useState({});
    const [paymentType, setPaymentType] = useState(null);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const [amountError, setAmountError] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [selectValue, setSelectValue] = useState("");

    const [showAdvancePaymentModal, setShowAdvancePaymentModal] = useState(false);
    const [showOverPaymentModal, setShowOverPaymentModalModal] = useState(false);
    const [showPSEModal, setShowPSEModal] = useState(false);
    const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);
    const [showNequiModal, setShowNequiModal] = useState(false);

    const [opOption, setOpOption] = useState(0);
    const [apOption, setApOption] = useState(0);

    const [sendOriginations, setSendOriginations] = useState(false);
    const [sendInvitations, setSendInvitations] = useState(false);
    const { GetSignedDocuments } = OlimpiaServices();
    const [showEfectyModal, setShowEfectyModal] = useState(false);
    const [showPdfContratoModal, setShowPdfContratoModal] = useState(false);
    const handleClosePdfContratoModal = () => setShowPdfContratoModal(false);
    const [pdfContrato, setPdfContrato] = useState({});
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [show, setShow] = useState(false);

    const [bank, setBank] = useState("0");
    const [bankList, setBankList] = useState([]);
    const [FPayment, setFPayment] = useState(0);

    const [intervalNequi, setIntervalNequi] = useState(0);
    const [QRvalue, setQRvalue] = useState(null);
    const [showAnotherNumberForm, setShowAnotherNumberForm] = useState(false);
    const [anotherNumber, setAnotherNumber] = useState("");
    const [anotherNumberError, setAnotherNumberError] = useState("");
    const [pendingUPPayment, setPendingUPPayment] = useState(false);
    const [transactionIdNequi, setTransactionIdNequi] = useState();

    const handleClose = () => setShow(false);
    const handleShow = (credit) => {

        setModalCredit(credit);
        setShow(true)
    }

    const handleEfectyModalClose = () => {
        setShowEfectyModal(false);
    }

    const handlePSEModalClose = () => {
        setShowPSEModal(false);
    }

    const handleChangeBank = (value) => {
        setBank(value);
    }

    const GetUrlPSE = () => {

        if (!bank || bank === "0") {

            Swal.fire({
                html: "Seleccione el banco",
                showDenyButton: false,
                confirmButtonText: 'ACEPTAR',
                confirmButtonColor: "#00a9eb",
                icon: 'error'
            });

            return;

        }

        setSending(true);
        GetPSEUrl("CHAN", bank, selectedReference.Reference, paymentType.PaymentReferencePSE, paymentType.AmountInCents / 100, paymentType.LabelPayment).then(res => {

            if (res.status === 200) {
                var data = JSON.parse(res.data.data);
                window.location.replace(data.pseURL);
            }
            else {
                setSending(false);
                Swal.fire({
                    html: res.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });

            }


        })
    }
    useEffect(() => {
        request = getStorage("request")
        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" })

        GetBankList().then(res => {
            var data = JSON.parse(res.data?.data ?? null);
            setBankList(data ?? [])
        });

        calculate();


        //setShowModalFB(true)

        if (request.creditApproved === 1) {
            //setShowModalFB(true)

            MySwal.fire({
                html: request.creditApprovedMessage,
                confirmButtonText: "Entendido",
                allowOutsideClick: false,
                allowEscapeKey: false
            })

            setStorage("request", {
                ...request,
                creditApproved: 0
            });
        }




    }, []);

    useEffect(() => () => {
        clearInterval(intervalNequi);

    }, []);

    const [, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const submitWompi = (id) => {

        let pDiv = document.getElementById(id).parentElement.querySelector('.waybox-button');;

        pDiv.click();
    }

    const calculate = () => {
        setSending(true);
        setSendInvitations(true);
        setSendOriginations(true);
        CalculateCreditLimit().then(res => {

            setSending(false);

            getCredits();
            //setExpandCredits(true)
            getInvitations();


            if (res.status === 200) {
                let data = JSON.parse(res.data.data);
                setcreditData(data)

                if (data.EnabledCreditLimit && !data.CurrentCreditLimit) {
                    setRetryButton(true)
                    setUpdateProfile(false)


                    if (data.CreditLimit.ShowMessage) {

                        MySwal.fire({
                            html: data.CreditLimit.ResultDetail,
                            icon: 'warning',
                            showCancelButton: true,
                            cancelButtonText: "Cerrar",
                            confirmButtonText: "Reintentar"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                calculate()
                            }
                        })


                    }


                }
                else if (data.EnabledCreditLimit && data.CurrentCreditLimit) {
                    setRetryButton(true)
                    setUpdateProfile(false)
                    if (data.CreditLimit.ShowMessage) {
                        MySwal.fire({
                            html: data.CreditLimit.ResultDetail,
                            icon: data.CreditLimit.Result === "DEN" ? "warning" : 'success'
                        })
                    }


                    setCreditLimit(data.CreditLimit.CreditLimit)
                    setExpirationCreditLimit(data.CreditLimit.Result === "DEN" ? "" : "Recuerda que tu cupo vence el " + data.CreditLimit.DateExpirationLabel)
                }
                else if (!data.EnabledCreditLimit && data.CurrentCreditLimit) {
                    setRetryButton(true)
                    setUpdateProfile(false)
                    if (data.CreditLimit.ShowMessage) {
                        MySwal.fire({
                            html: data.CreditLimit.ResultDetail,
                            icon: data.CreditLimit.Result === "DEN" ? "warning" : 'success'
                        })
                    }


                    setCreditLimit(data.CreditLimit.CreditLimit)
                    setExpirationCreditLimit(data.CreditLimit.Result === "DEN" ? "" : "Recuerda que tu cupo vence el " + data.CreditLimit.DateExpirationLabel)
                }
                else if (!data.EnabledCreditLimit && !data.CurrentCreditLimit) {
                    setRetryButton(true)
                    MySwal.fire({
                        html: res.data.message,
                        icon: 'info',
                        showCancelButton: true,
                        cancelButtonText: "Cerrar",
                        confirmButtonText: "Actualiza tu perfil",
                        confirmButtonColor: "#699bff"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            goToProfile()
                        }
                    })

                    setUpdateProfile(true)
                }
            }
            else {
                setRetryButton(true)
                setUpdateProfile(false)
                MySwal.fire({
                    html: res.data.message,
                    icon: 'error',
                    showCancelButton: true,
                    cancelButtonText: "Cerrar",
                    confirmButtonText: "Reintentar"
                }).then((result) => {
                    if (result.isConfirmed) {
                        calculate()
                    }
                })
            }

        })




    }

    const handleAnotherNumberNequi = () => {
        setShowAnotherNumberForm(true);
    }

    const openNequiModal = () => {
        setShowNequiModal(true)
    }

    const CreateNequiPaymentI = (type) => {

        setSending(true);
        CreateNequiPayment(anotherNumber, selectedReference.Reference, paymentType.PaymentReferenceNEQUI, paymentType.AmountInCents / 100, type).then(res => {

            setSending(false);
            if (res.status === 200) {
                setShowAnotherNumberForm(false)
                let data = JSON.parse(res.data.data);
                setTransactionIdNequi(data.transactionId)
                if (type === "QR") {
                    setQRvalue("bancadigital-" + data.transactionId)
                }
                else {
                    setPendingUPPayment(true)
                }

                let inequi = setInterval(() => {


                    GetNequiPayment(data.transactionId).then(resG => {
                        let dataG = JSON.parse(resG.data.data);

                        if (dataG && dataG.status && dataG.status !== "PENDING") {
                            clearInterval(inequi);

                            navigate("/paymentResultNequi?transactionId=" + data.transactionId)
                        }

                    });


                }, 30 * 1000);


                setIntervalNequi(inequi);

            }
            else {

                handleNequiModalClose();
                Swal.fire({
                    html: res.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });
            }
        })
    }

    const getInvitations = () => {

        GetInvitations().then(res => {
            setSendInvitations(false);
            if (res.status === 200) {

                let data = JSON.parse(res.data.data);

                if (data && data.Invitations) {

                    setExpandInvitations(true);


                    data.Invitations.forEach(element => {

                        if (typeof element.Treatments === 'string') {
                            element.Treatments = JSON.parse(element.Treatments)
                        }
                    });
                    setInvitations(data.Invitations)


                }



            } else {
                MySwal.fire({
                    html: res.data.message,
                    icon: 'error'
                })
            }

        })
    }
    const getCredits = () => {



        GetApplicationsByCustomer().then(res => {
            let data = JSON.parse(res.data.data)

            setApplications(data)

        })

        GetOriginattionsByCustomer().then(res => {
            let data = JSON.parse(res.data.data)

            setOriginations(data)
            setSendOriginations(false);
        })

    }

    const ratingChanged = (newRating) => {
        // console.log(newRating)
    }


    const VerContrato = (idApplication) => {

        setLoadingPdf(true)
        GetSignedDocuments({
            idApplication: idApplication,
            documentType: [
                3
            ],
            setPasswordToDocuments: false
        }).then(res => {
            setLoadingPdf(false)
            if (res.status === 200) {
                let data = JSON.parse(res.data.data).documents[0]
                setPdfContrato({ pdf: data.BS64, name: data.Name })
                setShowPdfContratoModal(true)
            }
        })
    }


    useEffect(() => {



        let creditsTemp = []


        if (originations && originations.length > 0) {



            originations.forEach(element => {

                if (element.Penalty > 0)
                    setExpandPenaltyCredits(true)

                creditsTemp.push({
                    ...element,
                    Reference: element.Reference,
                    posCommercialName: element.posCommercialName,
                    LabelMinimumPayment: element.LabelMinimumPayment,
                    LimitDate: element.LimitDate ?? "-",
                    LabelPenalty: element.LabelPenalty,
                    LabelBalance: element.LabelBalance,
                    NextPayment: element.NextPayment,
                    LastPayment: element.LastPayment,
                    RemainingTerm: element.RemainingTerm,
                    ColorLimitDate: element.ColorLimitDate,
                    ColorMinimumPayment: element.ColorMinimumPayment,
                    type: "origination"
                })
            });
        }

        if (applications && applications.length > 0) {


            applications.forEach(element => {

                creditsTemp.push({
                    ...element,
                    Reference: element.Reference,
                    posCommercialName: element.posCommercialName,
                    LabelMinimumPayment: "$0",
                    LimitDate: "-",
                    LabelPenalty: "$0",
                    LabelBalance: element.LabelApplicationAmount,
                    NextPayment: element.FirstInstDefaultDate,
                    LastPayment: element.LastPaymet,
                    RemainingTerm: element.Term,
                    type: "application"
                })
            });
        }

        if (applications && applications.length === 0 && originations && originations.length === 0) {
            setExpandCredits(true)

        }
        else {
            setExpandCredits(false)
        }



        setCredits(creditsTemp)



    }, [applications, originations]);

    const salir = () => {
        logout();
        clearRequest();
        navigate("/");
    }

    const goToProfile = () => {


        setStorage("request", {
            ...request,
            comingFromDashboard: true,
            state: 4
        });

        navigate("/Profile");
    }

    const validInvitation = (i) => {


        setStorage("request", {
            ...request,
            invitation: i,
            state: 10
        });

        navigate("/ApplicationRequest");
    }

    const invalidInvitation = (message) => {
        MySwal.fire({
            html: message,

        })
    }

    const getSVG = (htmlString, color) => {
        const parser = new DOMParser();
        const html = parser.parseFromString(htmlString, 'text/html');
        const svg = html.body.getElementsByTagName("svg")[0];

        svg.setAttribute("width", "30px")
        svg.setAttribute("height", "30px")
        svg.setAttribute("fill", color)

        var serializedXml = new XMLSerializer().serializeToString(svg);
        return serializedXml;

    }
    const tooltip = (
        <Tooltip id="tooltip">
            Esta es la tasa que usamos para generar la cuota, la cual nunca podrá ser superior a la tasa de usura aplicable para este periodo de cuota
        </Tooltip>
    );


    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () => { },
        );

        return (
            <button
                type="button"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    border: "solid",
                    borderRadius: "10px",

                    color: "black",
                    display: "inline-flex",
                    fontSize: "14px",
                    borderColor: "gray"
                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    function CustomToggleOtherOPS({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
        // console.log('totally custom!')
        { },
        );

        return (
            <button
                type="button"
                style={{
                    backgroundColor: "white",
                    width: "100%",
                    border: "none",

                    color: "black",
                    display: "inline-flex",
                    fontSize: "14px",

                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    const handlePendingPaymentModalClose = () => {

        setShowPendingPaymentModal(false);
    }

    const handleOpenPaymentModal = (r) => {

        setShowPaymentModal(true)

        setSelectedReference(r)

        let tempMIP = r.Payments.find(p => p.IdConcept === "MIP");
        setSelectedMinimumPayment(tempMIP)
        if (tempMIP?.Enabled) {
            setPaymentType(r.Payments.find(s => s.IdConcept === "MIP"))
            setSelectValue("MIP")
        }
    }

    const handlePaymentModalClose = () => {
        setPaymentType(null)
        setShowPaymentModal(false);
    }

    const handleChangePaymentType = (type) => {
        setSelectValue(type)
        setPaymentType(null)
        setAmountError(false)


        if (type === "MAN") {
            setPaymentType({ LabelAmount: "", IdConcept: "MAN" })
        }
        else if (type) {

            setPaymentType(selectedReference.Payments.find(s => s.IdConcept === type))
        }
        else {
            setPaymentType(null)
        }

    }

    const handleNequiModalClose = () => {
        setQRvalue(null);
        setShowAnotherNumberForm(false);
        setAnotherNumber("")
        clearInterval(intervalNequi);
        setShowNequiModal(false);
    }

    const validateManualPayment = () => {

        let amount = Number(paymentType.LabelAmount.replaceAll("$", "").replaceAll(".", ""));
        let totalPayment = selectedReference.Payments.find(j => j.IdConcept === "TOP")?.AmountInCents / 100;

        if (!amount) {
            setAmountError(true)
        } else if (amount < 5000) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser mayor a $5.000",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else if (amount > 2000000000) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser menor a $2.000.000.000",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else if (amount > totalPayment) {
            setAmountError(true)
            Swal.fire({
                html: "El valor debe ser menor al valor total adeudado",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });
        }
        else {
            setAmountError(false)

            let minimumPayment = selectedMinimumPayment.AmountInCents / 100;

            if (minimumPayment === 0) {

                setShowAdvancePaymentModal(true);
            }
            else if (amount > minimumPayment) {

                if (selectedReference.IsBilled && !selectedReference.IsInDefault) {
                    setShowPendingPaymentModal(true);
                }
                else {
                    setShowOverPaymentModalModal(true)
                }


            }
            else if (amount < minimumPayment) {

                if (selectedReference.IsBilled && !selectedReference.IsInDefault) {
                    setShowPendingPaymentModal(true);
                }
                else {
                    getCustomPaymentAmount(amount, null);
                }
            }
            else {

                getCustomPaymentAmount(amount, null);
            }
        }
    }


    const handlePendingPayment = (type) => {
        let amount = Number(paymentType.LabelAmount.replaceAll("$", "").replaceAll(".", ""));
        let minimumPayment = selectedMinimumPayment.AmountInCents / 100;

        if (type === 1) {
            setFPayment(1)
            if (amount < minimumPayment) {
                getCustomPaymentAmount(amount, null);

            } else {
                setShowOverPaymentModalModal(true)
            }
        }
        else {

            Swal.fire({
                title: "Vas a entrar en mora...",
                html: `...si no pagas tu cuota de <b>${selectedMinimumPayment?.LabelAmount}</b> antes del <b>${selectedMinimumPayment.LabelLimitDate}</b>. Te recomendamos pagar tu cuota antes de abonar a capital`,
                showDenyButton: true,
                denyButtonText: "Abonar todo a capital",
                denyButtonColor: "grey",
                confirmButtonText: 'Pagar cuota<br/>',

                confirmButtonColor: "#00A9EB",
                iconColor: "red",
                icon: 'info'
            }).then((result) => {

                if (result.isDenied) {
                    setFPayment(2);
                    setShowOverPaymentModalModal(true)
                }

                if (result.isConfirmed) {
                    setFPayment(1)
                    if (amount < minimumPayment) {
                        getCustomPaymentAmount(amount, null);

                    } else {
                        setShowOverPaymentModalModal(true)
                    }
                }

            });
        }
        setShowPendingPaymentModal(false)
    }

    const handleTransferModalClose = () => {
        setShowTransferModal(false);
    }

    const getCustomPaymentAmount = (amount, option) => {
        let body = {
            reference: selectedReference.Reference,
            amount: amount,
            paymentOption: option
        };
        setSending(true)
        CustomPaymentAmount(body).then(res => {
            setSending(false)

            if (res.status === 200) {

                setShowOverPaymentModalModal(false)
                setShowAdvancePaymentModal(false)

                let data = JSON.parse(res.data.data)


                if (!data.hasError) {

                    if (data.hasWarning) {
                        Swal.fire({
                            html: data.warningMessage,
                            showDenyButton: true,
                            denyButtonText: 'REGRESAR',
                            confirmButtonText: 'CONTINUAR',
                            confirmButtonColor: "#00a9eb",
                            denyButtonColor: "gray",
                            icon: 'warning'
                        }).then((result) => {
                            setShowPendingPaymentModal(false)
                            if (result.isConfirmed) {
                                let temp = data.CustomerReferences[0].Payments[0];
                                setSelectedReference({ ...selectedReference, Payments: [...selectedReference.Payments, temp] })
                                setPaymentType(temp)
                            } else if (result.isDenied) {

                            }
                        })
                    } else {
                        let temp = data.CustomerReferences[0].Payments[0];
                        setSelectedReference({ ...selectedReference, Payments: [...selectedReference.Payments, temp] })
                        setPaymentType(temp)
                    }

                }
                else {
                    Swal.fire({
                        html: data.errorMessage,
                        showDenyButton: false,
                        confirmButtonText: 'CONTINUAR',
                        confirmButtonColor: "#00a9eb",
                        icon: 'error'
                    });
                    setShowOverPaymentModalModal(false)
                    setShowAdvancePaymentModal(false)
                    setShowPendingPaymentModal(false)
                    setPaymentType({ LabelAmount: "", IdConcept: "MAN" })
                }
            }

        })
    }

    const getStatusNequi = () => {

        setSending(true)
        GetNequiPayment(transactionIdNequi).then(resG => {
            setSending(false)
            let dataG = JSON.parse(resG.data.data);

            if (resG.status === 200) {

                if (dataG && dataG.status && dataG.status !== "PENDING") {

                    navigate("/paymentResultNequi?transactionId=" + transactionIdNequi)
                }
                else {

                    Swal.fire({
                        title: "El pago se encuentra pendiente",
                        html: "Completa el pago con tu aplicación de Nequi",
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#00a9eb",
                        icon: 'info'
                    })
                }
            }
            else {
                handleNequiModalClose();
                Swal.fire({
                    html: resG.data.message,
                    showCancelButton: true,
                    cancelButtonText: "Contactanos",
                    cancelButtonColor: "#ff9e25",
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#00a9eb",
                    icon: 'error'
                }).then((result) => {
                    if (!result.isConfirmed) {
                        window.open("https://www.meddipay.com.co/contact", "_blank", "noreferrer")
                    }
                });
            }


        });
    }

    const overPaymentRequest = () => {

        if (opOption === 0) {
            Swal.fire({
                html: "Seleccione una opción",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });

            return;
        }
        let amount = Number(paymentType.LabelAmount.replaceAll("$", "").replaceAll(".", ""));

        if (FPayment === 0 || FPayment === 1) {
            getCustomPaymentAmount(amount, opOption === 1 ? "NEI" : opOption === 2 ? "RET" : "REI");
        }
        else {
            getCustomPaymentAmount(amount, opOption === 1 ? "NEI" : opOption === 2 ? "NIRET" : "NIREI");
        }


        setOpOption(0)
        setShowPendingPaymentModal(false)
    }


    const advancePaymentRequest = () => {

        if (apOption === 0) {
            Swal.fire({
                html: "Seleccione una opción",
                showDenyButton: false,
                confirmButtonText: 'CONTINUAR',
                confirmButtonColor: "#00a9eb",
                icon: 'warning'
            });

            return;
        }
        let amount = Number(paymentType.LabelAmount.replaceAll("$", "").replaceAll(".", ""));
        getCustomPaymentAmount(amount, apOption === 1 ? "NEI" : apOption === 2 ? "RET" : "REI");
        setApOption(0)
    }

    const handleOpOption = (op) => {
        setOpOption(op);
    }

    const handleApOption = (op) => {
        setApOption(op);
    }

    const handleAdvancePaymentModalClose = () => {
        setApOption(0)
        setShowAdvancePaymentModal(false);
    }

    const handleOverPaymentModalClose = () => {
        setOpOption(0)
        setShowOverPaymentModalModal(false);
    }


    const downloadPdf = () => {
        const linkSource = `data:application/pdf;base64,${pdfContrato.pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = pdfContrato.name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <>

            <table id="dashboard-table" >

                <tbody>

                    <tr>
                        <td >
                            <h1>Cupo disponible</h1>
                        </td>
                    </tr>
                    {sending ?
                        <>
                            <tr>
                                <td >
                                    <br />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Skeleton height={20} width={300} />
                                </td>
                            </tr>
                            <tr>
                                <td><Skeleton height={40} width={170} /></td>
                            </tr>
                            <tr>
                                <td><br /></td>
                            </tr>
                            <tr>
                                <td><Skeleton height={20} width={90} /></td>
                            </tr>
                        </> :
                        <>
                            <tr>
                                <td >
                                    <br />
                                </td>
                            </tr>

                            <tr>
                                <td style={{ fontSize: "12px" }}>
                                    {expirationCreditLimit}
                                </td>
                            </tr>

                            <tr>
                                <td style={{ fontSize: "25px" }}>
                                    <b>$ {moneyLocale.format(creditLimit)}</b>

                                    <i style={{ cursor: "pointer", display: retryButton ? "initial" : "none" }} data-toggle="tooltip" data-placement="top" title="Reintentar" className="bi bi-arrow-counterclockwise" onClick={calculate}></i>

                                </td>
                            </tr>
                        </>}


                    <tr>
                        <td>
                            <br />
                        </td>
                    </tr>
                    {creditData &&
                        <tr>
                            <td>
                                <div style={{ cursor: "pointer", borderRadius: "5px", fontSize: "15px", width: "50%", margin: "0 auto" }}
                                    onClick={
                                        () => {
                                            MySwal.fire({
                                                html: creditData.CurrentCreditLimit && creditData.CreditLimit.Result === "APP" ? <p>Una vez aprobado tu cupo, elige a uno de <a target="_blank" href="https://www.meddipay.com.co/ourallies">nuestros aliados</a> y visita la clínica para solicitar el presupuesto de tu tratamiento. Para financiarlo con Meddipay, simplemente comunica al aliado la cantidad que deseas financiar y este procederá a enviarte la invitación por dicho monto, visible en esta pantalla. Al acceder, podrás elegir el plan de pagos que mejor se adapte a tu bolsillo y completar el proceso con la firma del contrato.</p> : creditData.CreditLimit.ResultDetail,
                                                icon: 'question',
                                                showCancelButton: true,
                                                cancelButtonText: "Cerrar",
                                                showConfirmButton: false
                                            })
                                        }
                                    }>
                                    <span style={{ color: "#3366CC" }}>{creditData.CurrentCreditLimit && creditData.CreditLimit.Result === "APP" ? "¿Y ahora qué?" : "¿Por qué mi cupo es $0?"}</span>
                                </div>

                            </td>
                        </tr>}
                    {/* <tr>
                        <td>
                            <br />
                        </td>
                    </tr> */}
                    <tr style={{ display: updateProfile ? "contents" : "none" }}>
                        <td>
                            <button type="button" className="btn btn-dashboard" id="btn-update-profile" onClick={goToProfile}>Actualiza tu perfil</button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <br />
                        </td>
                    </tr>

                    <tr>
                        <td >

                            {sendOriginations ?
                                <Accordion defaultActiveKey="0" className="dashboard-accordion" style={{ marginBottom: "20px" }} flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Mis créditos <Skeleton style={{ right: "50px", position: "absolute" }} height={20} width={20} /></Accordion.Header>
                                        <Accordion.Body>
                                            <Skeleton height={200} width="100%" />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                :
                                <Accordion defaultActiveKey={expandPenaltyCredits || expandCredits || !expandInvitations ? "0" : "-1"} className="dashboard-accordion" style={{ marginBottom: "20px" }} flush>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Mis créditos <Badge className={credits.length === 0 ? "bage-gray-custom" : "bage-primary-custom"} style={{ right: "50px", position: "absolute", borderRadius: "11px" }}>{credits.length}</Badge> </Accordion.Header>
                                        <Accordion.Body>

                                            {credits.length > 0 ? (credits.map(c => {

                                                let p = c.Payments?.find(p => p.IdConcept === "MIP");
                                                return (<Card style={{ width: "95%", margin: "0 auto", marginBottom: "20px", borderRadius: "14px", padding: "5px 15px" }} key={c.Reference}>
                                                    <ListGroup variant="flush">



                                                        <ListGroup.Item>
                                                            <div style={{ display: "inline-flex", width: "100%" }}>
                                                                <div style={{ marginRight: "15px", fontSize: "30px" }}>

                                                                    <i className="bi bi-hash"></i>
                                                                </div>
                                                                <div style={{ textAlign: "left" }}>
                                                                    <span style={{ fontWeight: "bold" }}>{c.Reference}</span>
                                                                    <div style={{ fontSize: "10px", color: "gray" }}>{c.posCommercialName}</div>
                                                                </div>

                                                            </div>

                                                        </ListGroup.Item>



                                                        <ListGroup.Item style={{ paddingRight: "0", paddingLeft: "0" }}>


                                                            {!["ACT", "APR"].includes(c.StatusCode) ? <>
                                                                <div style={{
                                                                    position: "absolute",
                                                                    zIndex: "999",
                                                                    pointerEvents: "none",
                                                                    width: "50%",
                                                                    fontSize: "3vh",
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                    left: "0",
                                                                    right: "0",
                                                                    top: "30%",
                                                                    border: "solid 2px",
                                                                    padding: "0px 10px 0px 10px",
                                                                    marginLeft: "auto",
                                                                    marginRight: "auto"


                                                                }}>{c.LabelStatus}</div>

                                                                <Container style={{ paddingRight: "0", paddingLeft: "0", opacity: 0.3 }}>
                                                                    <Row>
                                                                        <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: "left", paddingRight: "0px" }}>
                                                                            <span className="titulos-mis-creditos" >Fecha firma</span>
                                                                            <br />
                                                                            <span className="textos-mis-creditos" >{c.SignatureDate2}</span>
                                                                        </Col>
                                                                        <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ textAlign: "right", paddingLeft: "0px" }}>
                                                                            <span className="titulos-mis-creditos">Plazo</span>
                                                                            <br />
                                                                            <span className="textos-mis-creditos" >{c.LabelTerm}</span>
                                                                        </Col>

                                                                    </Row>

                                                                    <Row>
                                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "left", paddingRight: "0px" }}>
                                                                            <span className="titulos-mis-creditos">Valor financiado</span>
                                                                            <br />
                                                                            <span className="textos-mis-creditos">{c.LabelOriginationAmount}</span>
                                                                        </Col>
                                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", paddingLeft: "0px" }}>
                                                                            <span className="titulos-mis-creditos">Fecha cierre</span>
                                                                            <br />
                                                                            <span className="textos-mis-creditos">{c.DateStatus ?? "N/A"}</span>
                                                                        </Col>

                                                                    </Row>

                                                                </Container>

                                                            </> : <Container style={{ paddingRight: "0", paddingLeft: "0" }}>
                                                                <Row>
                                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ textAlign: "left", paddingRight: "0px" }}>
                                                                        <span className="titulos-mis-creditos" >Pago Mínimo</span>
                                                                        <br />
                                                                        <span className="textos-mis-creditos" style={{ color: c.ColorMinimumPayment }}>{c.LabelMinimumPayment}</span>
                                                                    </Col>
                                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ textAlign: "right", paddingLeft: "0px" }}>
                                                                        <span className="titulos-mis-creditos">Fecha máxima de pago</span>
                                                                        <br />
                                                                        <span className="textos-mis-creditos" style={{ color: c.ColorLimitDate }}>{c.LimitDate}</span>
                                                                    </Col>

                                                                </Row>

                                                                <Row>
                                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "left", paddingRight: "0px" }}>
                                                                        <span className="titulos-mis-creditos">Pago total</span>
                                                                        <br />
                                                                        <span className="textos-mis-creditos">{c.LabelBalance}</span>
                                                                    </Col>
                                                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "right", paddingLeft: "0px" }}>
                                                                        <span className="titulos-mis-creditos">Cuotas restantes</span>
                                                                        <br />
                                                                        <span className="textos-mis-creditos">{c.RemainingTerm}</span>
                                                                    </Col>

                                                                </Row>

                                                            </Container>
                                                            }
                                                        </ListGroup.Item>





                                                        <ListGroup.Item>

                                                            <div style={{ display: "inline-flex", width: "100%" }}>
                                                                <div style={{ textAlign: "left", width: "60%", color: "#39a6de", paddingTop: "5px" }}>
                                                                    <span style={{ cursor: "pointer" }} onClick={() => {
                                                                        if (c.type === "origination") { handleShow(c); }
                                                                        else {
                                                                            MySwal.fire({
                                                                                html: "Estamos preparando toda la información, por favor, vuelve a intentarlo pasados unos minutos",
                                                                                icon: 'info'
                                                                            })
                                                                        }

                                                                    }}>Más detalles</span>

                                                                </div>
                                                                <div style={{ textAlign: "right", width: "40%", color: "#39a6de" }}>

                                                                    {/* {p && p.AmountInCents && p.AmountInCents > 0 ? <WompiButton data={{
                                                                    AmountInCents: p.AmountInCents,
                                                                    PaymentReference: p.PaymentReference,
                                                                    EncryptedString: p.EncryptedString,
                                                                }} /> : (<></>)} */}

                                                                    {p && p.AmountInCents && p.AmountInCents > 0 && c.type === "origination" && c.StatusCode === "ACT" ? <input type="submit" value="Pagar" className="btn btn-mp-back" onClick={() => handleOpenPaymentModal(c)} style={{ border: "none", backgroundColor: "rgb(0, 169, 235)", minWidth: "100px" }} /> :
                                                                        c.type === "origination" && c.StatusCode === "ACT" ? <input type="submit" value="Abonar" className="btn btn-mp-back" onClick={() => handleOpenPaymentModal(c)} style={{ border: "none", backgroundColor: "#79d853", minWidth: "100px" }} /> : <></>}
                                                                </div>
                                                            </div>


                                                        </ListGroup.Item>

                                                    </ListGroup>

                                                </Card>
                                                )

                                            })) : <span style={{ fontSize: "12px" }}>Esta es la sección de tus créditos! Aquí encontrarás todas las invitaciones de tus doctores que hayas aceptado y firmado. Además, podrás revisar tus movimientos y realizar el pago de tus cuotas cómodamente.</span>}



                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>
                            }

                            {sendInvitations ?

                                <Accordion defaultActiveKey="1" className="dashboard-accordion" style={{ marginBottom: "20px" }} flush>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Mis invitaciones <Skeleton style={{ right: "50px", position: "absolute" }} height={20} width={20} /></Accordion.Header>
                                        <Accordion.Body>
                                            <Skeleton height={200} width="100%" />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion> :

                                <Accordion defaultActiveKey={!expandPenaltyCredits ? "1" : "-1"} className="dashboard-accordion" flush>

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            Mis invitaciones
                                            <Badge style={{ right: "50px", position: "absolute", borderRadius: "11px" }} className={!invitations || invitations.length === 0 ? "bage-gray-custom" : "bage-green-custom"}>{invitations.length}
                                            </Badge>

                                        </Accordion.Header>
                                        <Accordion.Body>



                                            {invitations.length > 0 ? (invitations.map(i => {
                                                return (

                                                    <Card style={{ width: "95%", margin: "0 auto", marginBottom: "20px", borderRadius: "14px", padding: "20px 15px", boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)" }} key={i.IdInvitation}>
                                                        <ListGroup variant="flush">
                                                            <ListGroup.Item>
                                                                <div className="invitation-title-div">

                                                                    {i.PosIconURL && <img style={{ width: "80px" }} src={i.PosIconURL}></img>}

                                                                    {!i.PosIconURL && <div style={{ padding: "5px" }} dangerouslySetInnerHTML={{ __html: i.PosSectorIcon ? getSVG(i.PosSectorIcon, i.MainColor) : <></> }}>

                                                                    </div>}
                                                                    <div style={{ fontWeight: "bold" }}>
                                                                        {i.CommercialName}
                                                                        <div style={{ fontSize: "10px" }}><span style={{ color: "gray" }}>{i.LabelDateCreated}</span> | {i.LabelExpirationDate}</div>
                                                                    </div>

                                                                </div>

                                                            </ListGroup.Item>
                                                            <ListGroup.Item>

                                                                <div style={{ display: "inline-flex", width: "100%" }}>
                                                                    <div style={{ textAlign: "left", width: "50%", fontSize: "20px", padding: "5px" }}>
                                                                        {i.LabelAmountFinance}

                                                                    </div>
                                                                    <div style={{ textAlign: "right", width: "50%", padding: "9px" }} onClick={() => { i.ValidInvitation ? validInvitation(i) : invalidInvitation(i.InvitationMessage) }}>
                                                                        <span style={{ cursor: "pointer", "color": i.ValidInvitation ? "#39a6de" : "gray", fontWeight: "bold" }} >{i.ValidInvitation ? "Acceder" : <i className="bi bi-info-circle"></i>}</span>
                                                                    </div>
                                                                </div>


                                                            </ListGroup.Item>

                                                        </ListGroup>
                                                    </Card>



                                                )
                                            })) : <span style={{ fontSize: "12px" }}>Esta es la sección de invitaciones para acceder al financiamiento de tus tratamientos. Si no localizas la invitación que necesitas, te recomendamos actualizar la página o comunicarte con tu médico para confirmar su generación o solicitar una nueva.</span>}

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            }
                        </td>

                    </tr>

                    <tr>
                        <td style={{ textAlign: "center", padding: "10px" }} >

                            <input type="submit" value="Actualizar" className="btn btn-mp-back" onClick={calculate} style={{ marginTop: "40px", width: "100px" }} />
                        </td>



                    </tr>

                    <tr>
                        <td style={{ textAlign: "center", padding: "10px" }} >
                            <input type="submit" value="Salir" className="btn btn-salir" onClick={salir} style={{ width: "100px" }} />

                        </td>



                    </tr>

                    {/* <tr>
                        <td style={{ textAlign: "center", padding: "10px" }} >
                            <input type="submit" value="TEMP" className="btn btn-salir" onClick={goToProfile} />
                        </td>
                    </tr> */}

                </tbody>
            </table>







            {modalCredit && <Modal show={show} onHide={handleClose} dialogClassName="modal-details">
                <Modal.Header closeButton>
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3} style={{ marginBottom: "0px" }}> <p style={{ fontSize: "20px", marginBottom: "0px" }}>Credito <span style={{ color: "#39a6de", fontWeight: "bold" }}>#{modalCredit.Reference}</span></p></Col>
                            <Col xs={12} sm={12} md={12} lg={9} xl={9} style={{ marginBottom: "0px" }}> <p style={{ fontSize: "20px", marginBottom: "0px" }}>{modalCredit.posCommercialName}</p></Col>
                        </Row>
                    </Container>

                </Modal.Header>

                <Modal.Body >

                    <Accordion flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Datos Generales</Accordion.Header>
                            <Accordion.Body>

                                <Container>

                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ marginBottom: "0px" }}>

                                            <Row>
                                                <Col xs={8} sm={8} md={6} lg={6} xl={6} style={{ fontSize: "15px", fontWeight: "bold" }}>Fecha Creación</Col>
                                                <Col xs={4} sm={4} md={6} lg={6} xl={6} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.DateCreated}</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={8} sm={8} md={6} lg={8} xl={8} style={{ fontSize: "15px", fontWeight: "bold" }}>Valor Financiado</Col>
                                                <Col xs={4} sm={4} md={6} lg={4} xl={4} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelOriginationAmount}</Col>
                                            </Row>

                                            <Row>
                                                <Col xs={8} sm={8} md={6} lg={6} xl={6} style={{ fontSize: "15px", fontWeight: "bold" }}>Plazo</Col>
                                                <Col xs={4} sm={4} md={6} lg={6} xl={6} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelTerm}</Col>
                                            </Row>

                                            <Row>
                                                <Col xs={4} sm={4} md={6} lg={4} xl={4} style={{ fontSize: "15px", fontWeight: "bold" }}>Tasa</Col>
                                                <Col xs={8} sm={8} md={6} lg={8} xl={8} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelInterestBase} / {modalCredit.LabelInterestBaseAnnual}</Col>
                                            </Row>

                                            <Row>
                                                <Col xs={8} sm={8} md={6} lg={6} xl={6} style={{ fontSize: "15px", fontWeight: "bold" }}>Saldo a Favor</Col>
                                                <Col xs={4} sm={4} md={6} lg={6} xl={6} style={{ fontSize: "15px", textAlign: "right", color: "#34e30c", fontWeight: "bold" }}>{modalCredit.LabelSuspenseAvailable}</Col>

                                            </Row>

                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                                            <Row>
                                                <Col xs={8} sm={8} md={6} lg={8} xl={8} style={{ fontSize: "15px", fontWeight: "bold" }}>Valor Cuota Mensual</Col>
                                                <Col xs={4} sm={4} md={6} lg={4} xl={4} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelBaseInstalment}</Col>
                                            </Row>

                                            <Row>
                                                <Col xs={8} sm={8} md={6} lg={6} xl={6} style={{ fontSize: "15px", fontWeight: "bold" }}>Pago Mínimo</Col>
                                                <Col xs={4} sm={4} md={6} lg={6} xl={6} style={{ fontSize: "15px", textAlign: "right", color: modalCredit.ColorMinimumPayment }}>{modalCredit.LabelMinimumPayment}</Col>
                                            </Row>

                                            <Row>
                                                <Col xs={3} sm={3} md={6} lg={4} xl={4} style={{ fontSize: "15px", fontWeight: "bold" }}>Ciclo</Col>
                                                <Col xs={9} sm={9} md={6} lg={8} xl={8} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelPayDay}</Col>
                                            </Row>

                                            <Row>
                                                <Col xs={7} sm={7} md={6} lg={6} xl={6} style={{ fontSize: "15px", fontWeight: "bold" }}>Pago Total</Col>
                                                <Col xs={5} sm={5} md={6} lg={6} xl={6} style={{ fontSize: "15px", textAlign: "right", fontWeight: "bold" }}>{modalCredit.LabelBalance}</Col>

                                            </Row>

                                            <Row>

                                                <Col xs={4} sm={4} md={6} lg={6} xl={6} style={{ fontSize: "15px", fontWeight: "bold" }}>Contrato</Col>
                                                <Col xs={8} sm={8} md={6} lg={6} xl={6} style={{ fontSize: "15px", textAlign: "right" }}>

                                                    {!loadingPdf ? <span style={{ cursor: "pointer" }} onClick={() => VerContrato(modalCredit.IdApplication)}><i className="bi bi-filetype-pdf"></i> Ver Contrato</span> :
                                                        <div className="spinner-border" role="status" style={{ width: "20px", height: "20px" }}></div>
                                                    }



                                                </Col>

                                            </Row>
                                        </Col>
                                    </Row>

                                </Container>
                                {/* <Container>
                                    <Row>
                                        <Col xs={8} sm={8} md={3} lg={3} xl={3} style={{ fontSize: "15px", fontWeight: "bold" }}>Fecha Creación</Col>
                                        <Col xs={4} sm={4} md={3} lg={3} xl={3} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.DateCreated}</Col>



                                        <Col xs={8} sm={8} md={3} lg={4} xl={4} style={{ fontSize: "15px", fontWeight: "bold" }}>Valor Cuota Mensual</Col>
                                        <Col xs={4} sm={4} md={3} lg={2} xl={2} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelBaseInstalment}</Col>
                                    </Row>
                                    <Row>

                                        <Col xs={8} sm={8} md={3} lg={4} xl={4} style={{ fontSize: "15px", fontWeight: "bold" }}>Valor Financiado</Col>
                                        <Col xs={4} sm={4} md={3} lg={2} xl={2} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelOriginationAmount}</Col>

                                        <Col xs={8} sm={8} md={3} lg={3} xl={3} style={{ fontSize: "15px", fontWeight: "bold" }}>Pago Mínimo</Col>
                                        <Col xs={4} sm={4} md={3} lg={3} xl={3} style={{ fontSize: "15px", textAlign: "right", color: modalCredit.ColorMinimumPayment }}>{modalCredit.LabelMinimumPayment}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={8} sm={8} md={3} lg={3} xl={3} style={{ fontSize: "15px", fontWeight: "bold" }}>Plazo</Col>
                                        <Col xs={4} sm={4} md={3} lg={3} xl={3} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelTerm}</Col>

                                        <Col xs={3} sm={3} md={3} lg={2} xl={2} style={{ fontSize: "15px", fontWeight: "bold" }}>Ciclo</Col>
                                        <Col xs={9} sm={9} md={3} lg={4} xl={4} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelPayDay}</Col>

                                    </Row>
                                    <Row>
                                        <Col xs={4} sm={4} md={3} lg={2} xl={2} style={{ fontSize: "15px", fontWeight: "bold" }}>Tasa</Col>
                                        <Col xs={8} sm={8} md={3} lg={4} xl={4} style={{ fontSize: "15px", textAlign: "right" }}>{modalCredit.LabelInterestBase} / {modalCredit.LabelInterestBaseAnnual}</Col>
                                        <Col xs={7} sm={7} md={3} lg={3} xl={3} style={{ fontSize: "15px", fontWeight: "bold" }}>Pago Total</Col>
                                        <Col xs={5} sm={5} md={3} lg={3} xl={3} style={{ fontSize: "15px", textAlign: "right", fontWeight: "bold" }}>{modalCredit.LabelBalance}</Col>
                                    </Row>

                                    <Row>

                                        <Col xs={8} sm={8} md={3} lg={3} xl={3} style={{ fontSize: "15px", fontWeight: "bold" }}>Saldo a Favor</Col>
                                        <Col xs={4} sm={4} md={3} lg={3} xl={3} style={{ fontSize: "15px", textAlign: "right", color: "#34e30c", fontWeight: "bold" }}>{modalCredit.LabelSuspenseAvailable}</Col>

                                        <Col xs={8} sm={8} md={3} lg={3} xl={3} style={{ fontSize: "15px", fontWeight: "bold" }}>Contrato</Col>
                                        <Col xs={4} sm={4} md={3} lg={3} xl={3} style={{ fontSize: "15px", textAlign: "right", color: "#34e30c", fontWeight: "bold" }}>PDF</Col>

                                    </Row>
                                </Container> */}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <br />
                    <Accordion flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Movimientos</Accordion.Header>
                            <Accordion.Body>

                                <Accordion flush style={{ margin: "0 auto" }} className="accordion-movements" >
                                    {
                                        modalCredit.Installments.map(i => {
                                            // return <Accordion.Item eventKey={i.NumInstalment} key={i.NumInstalment}>
                                            //     <Accordion.Header bsPrefix="header-instalment">{i.LabelInstalment} {i.LabelInstalmentStatus}</Accordion.Header>
                                            //     <Accordion.Body>
                                            //     </Accordion.Body>
                                            // </Accordion.Item>

                                            return <Card key={i.NumInstalment} style={{ border: "none", boxShadow: "none", marginBottom: "10px" }}>
                                                <Card.Header style={{ padding: "0", border: "none" }}>
                                                    <CustomToggle eventKey={i.NumInstalment}>
                                                        <div style={{ marginLeft: "10px", padding: "15px" }}>{i.LabelInstalment}</div>
                                                        <div style={{ position: "absolute", "right": "15px", padding: "10px" }}>
                                                            <div style={{ padding: "5px", color: i.ColorTextInstalmentStatus, backgroundColor: i.ColorFillInstalmentStatus, borderRadius: "5px", fontWeight: "500" }}>
                                                                {i.LabelInstalmentStatus}
                                                            </div>

                                                        </div>
                                                    </CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={i.NumInstalment}>
                                                    <Card.Body style={{ border: "none", padding: "5px", paddingTop: "20px", paddingBottom: "10px" }}>
                                                        <Container>

                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                                                    <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                                        <Row>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Pago Mínimo</Col>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelMinimumPayment}</Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Fecha de Facturación</Col>
                                                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelBillingDate}</Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Fecha Limite de Pago</Col>
                                                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelLimitDate}</Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Tasa</Col>
                                                                            <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelInterestBase} / {i.LabelInterestBaseAnnual} <OverlayTrigger placement="left" overlay={tooltip}>
                                                                                <i className="bi bi-question-circle"></i>
                                                                            </OverlayTrigger></Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Capital Pendiente</Col>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelRemainingCapital}</Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", marginBottom: "0px", paddingRight: "0px" }}>Valor Cuota / <span style={{ color: "#03A9F4" }}>Abonado</span></Col>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", marginBottom: "0px", paddingLeft: "0px" }}>{i.LabelTotalInstalment} / <span style={{ color: "#03A9F4" }}>{i.LabelPaidInstalment}</span></Col>
                                                                        </Row>
                                                                    </Container>
                                                                </Col>

                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                                                    <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                                        <Row>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Capital / <span style={{ color: "#03A9F4" }}>Abonado</span></Col>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelCapital} / <span style={{ color: "#03A9F4" }}>{i.LabelPaidCapital}</span></Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Intereses / <span style={{ color: "#03A9F4" }}>Abonado</span></Col>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelInterest} / <span style={{ color: "#03A9F4" }}>{i.LabelPaidInterest}</span></Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Seguro / <span style={{ color: "#03A9F4" }}>Abonado</span></Col>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelInsurance} / <span style={{ color: "#03A9F4" }}>{i.LabelPaidInsurance}</span></Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Aval / <span style={{ color: "#03A9F4" }}>Abonado</span></Col>
                                                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelGuarantee} / <span style={{ color: "#03A9F4" }}>{i.LabelPaidGuarantee}</span></Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={9} sm={9} md={9} lg={9} xl={9} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Comisión de Apertura / <span style={{ color: "#03A9F4" }}>Abonado</span></Col>
                                                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelOriginFee} / <span style={{ color: "#03A9F4" }}>{i.LabelPaidOriginFee}</span></Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xs={9} sm={9} md={9} lg={9} xl={9} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Intereses de Mora / <span style={{ color: "#03A9F4" }}>Abonado</span></Col>
                                                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{i.LabelPenalty} / <span style={{ color: "#03A9F4" }}>{i.LabelPaidPenalty}</span></Col>
                                                                        </Row>
                                                                    </Container>
                                                                </Col>
                                                            </Row>



                                                        </Container>

                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                        })
                                    }
                                </Accordion>


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <br />
                    <Accordion flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Pagos</Accordion.Header>
                            <Accordion.Body>

                                <Accordion flush style={{ margin: "0 auto" }} className="accordion-movements" >
                                    {modalCredit.Collections &&
                                        modalCredit.Collections.map(p => {
                                            // return <Accordion.Item eventKey={i.NumInstalment} key={i.NumInstalment}>
                                            //     <Accordion.Header bsPrefix="header-instalment">{i.LabelInstalment} {i.LabelInstalmentStatus}</Accordion.Header>
                                            //     <Accordion.Body>
                                            //     </Accordion.Body>
                                            // </Accordion.Item>

                                            return <Card key={p.Id} style={{ border: "none", boxShadow: "none", marginBottom: "10px" }}>
                                                <Card.Header style={{ padding: "0", border: "none" }}>
                                                    <CustomToggle eventKey={p.Id}>
                                                        <div style={{ marginLeft: "10px", padding: "15px", fontWeight: "bold" }}>{p.ValueDate}</div>
                                                        <div style={{ position: "absolute", "right": "15px", padding: "10px" }}>
                                                            <div style={{ padding: "5px", color: "white", backgroundColor: "rgb(76, 175, 80)", borderRadius: "5px", fontWeight: "500" }}>
                                                                {p.Amount}
                                                            </div>

                                                        </div>
                                                    </CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={p.Id}>
                                                    <Card.Body style={{ border: "none", padding: "5px", paddingTop: "20px", paddingBottom: "10px" }}>
                                                        <Container>

                                                            <Row>
                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ marginBottom: "0px" }}>

                                                                    <Row>
                                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Fecha Sistema</Col>
                                                                        <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{p.SystemDate}</Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Valor disponible</Col>
                                                                        <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{p.AvailableAmount}</Col>
                                                                    </Row>



                                                                </Col>

                                                                <Col xs={12} sm={12} md={12} lg={6} xl={6} >

                                                                    <Row>
                                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Canal pago</Col>
                                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{p.PaymentType}</Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", fontWeight: "bold", paddingRight: "0px" }}>Acción exceso</Col>
                                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ fontSize: "12px", textAlign: "right", paddingLeft: "0px" }}>{p.PaymentOptionExcess}</Col>
                                                                    </Row>



                                                                </Col>
                                                            </Row>



                                                        </Container>

                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                        })
                                    }
                                </Accordion>


                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </Modal.Body>


            </Modal>}



            <Modal show={showModalFB} onHide={() => { setShowModalFB(false) }} centered>
                <Modal.Header closeButton>
                    <h4>Tu opinión nos importa</h4>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <p>
                            Por favor, tómate un momento para calificarnos y dejarnos un breve comentario sobre tu experiencia:
                        </p>

                        <div style={{ width: "210px", margin: "0 auto" }}>
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                value={3}
                                size={50}
                                color2={'#ffd700'}
                                half={false}
                            />
                        </div>

                        <br />
                        <p>Comentarios:</p>
                        <textarea className="form-control" style={{ resize: "none", width: "100%" }}></textarea>
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModalFB(false) }}>Cancelar</Button>
                    <Button variant="custom" onClick={() => { setShowModalFB(false) }}>Enviar</Button>
                </Modal.Footer>
            </Modal>





            <Modal show={showPaymentModal} onHide={handlePaymentModalClose} dialogClassName="modal-payment">
                <Modal.Header closeButton>
                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference.Reference}</span>
                        </span>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    {selectedReference && <Container>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                                Pago mínimo
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment?.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                                {selectedMinimumPayment.LabelAmount}
                                <br />
                                <br />

                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                                Fecha límite de pago
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", color: selectedMinimumPayment?.Textcolor, fontWeight: selectedMinimumPayment.BoldText ? "bold" : "unset" }}>
                                {selectedMinimumPayment.LabelLimitDate ?? "-"}
                                <br />
                                <br />
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                                <span style={{ fontSize: "14px", color: "#00a9eb", cursor: "pointer" }} onClick={() => setShowPlanModal(true)}><i className="bi bi-table"> </i>Ver plan de pagos completo</span>
                                <br />
                                <br />
                            </Col>

                        </Row> */}


                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold" }}>
                                ¿Cuánto vas a pagar?

                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", marginTop: "10px" }}>

                                <select className="form-select" style={{ textAlign: "center" }} value={selectValue} onChange={(event) => handleChangePaymentType(event.target.value)}>
                                    <option defaultValue={null}></option>

                                    {selectedReference.Payments?.map(c => {
                                        if (c.IdConcept !== "MAV")
                                            return <option disabled={!c.Enabled} key={c.IdConcept} value={c.IdConcept}>{c.LabelPayment}</option>
                                    })}
                                    <option value="MAN">Introducir valor manual</option>
                                </select>
                            </Col>

                        </Row>

                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                {paymentType &&
                                    <>
                                        <div className="input-group">
                                            <MaskedInput placeholder="Introducir valor" disabled={paymentType.IdConcept !== "MAN"} style={{ marginTop: "20px", textAlign: "center", borderWidth: "2px" }} mask={currencyMask} name="valor-pagar" value={paymentType.LabelAmount} onChange={(event) => { setAmountError(false); setPaymentType({ ...paymentType, LabelAmount: event.target.value }) }} className={`form-control ${amountError && "is-invalid"}`} autoComplete="off">

                                            </MaskedInput >

                                            {paymentType.PaymentReference && paymentType.IdConcept === "MAV" && <div className="input-group-append" style={{ marginTop: "20px" }}>
                                                <span className="input-group-text" style={{ borderWidth: "2px", borderRadius: "0px", cursor: "pointer" }} onClick={() => { setPaymentType({ LabelAmount: "", IdConcept: "MAN" }) }}><i className="bi bi-x-lg"></i></span>

                                            </div>}
                                        </div>



                                        {/* <input disabled={paymentType.IdConcept !== "MAN"} style={{ width: "100%" }} type="text" value={paymentType.LabelAmount} onChange={(event) => { setPaymentType({ ...paymentType, LabelAmount: event.target.value }) }} className="d-flex justify-content-center gap-2 text-center  p-2 border  border-2 rounded-pill mt-4" /> */}

                                        {/* {paymentType.PaymentReference && paymentType.IdConcept === "MAV" && <button type="submit" style={{ margin: "20px 0px 0px", color: "white", textDecoration: "none", border: "none", minWidth: "200px", borderRadius: "70px", backgroundColor: "gray", height: "54px" }} onClick={() => { setPaymentType({ LabelAmount: "", IdConcept: "MAN" }) }} >Limpiar</button>} */}
                                        {paymentType.PaymentReference && <p style={{ fontSize: "13px", margin: "20px 0px 0px", }}>Este valor se ha calculado a fecha de <b>{dayjs().format("DD/MM/YYYY")}</b> y solamente es valido para pago realizados el mismo día.</p>}

                                        {paymentType.IdConcept === "MAN" && <button disabled={sending} type="submit" style={{ margin: "20px 0px 0px" }} className="btn btn-mp" onClick={validateManualPayment} >Validar</button>}


                                    </>

                                }


                            </Col>

                        </Row>


                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "0px" }}>
                                {paymentType && paymentType.PaymentReference && "¿Cómo vas a pagar?"}

                            </Col>

                        </Row>

                        {/* <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                          

                                {selectedReference && selectedReference.Payments && selectedReference.Payments.map(p => {
                                    if (p.AmountInCents > 0 && p.Enabled) {
                                        return <div key={p.PaymentReference + "_" + p.IdConcept} style={{ display: p.IdConcept === paymentType?.IdConcept && p.PaymentReference === paymentType?.PaymentReference ? "unset" : "none" }}>
                                            <WompiButton data={{
                                                AmountInCents: p.AmountInCents,
                                                PaymentReference: p.PaymentReference,
                                                EncryptedString: p.EncryptedString,
                                            }} />
                                        </div>
                                    }
                                })}


                            </Col>

                        </Row>

                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                                {paymentType && paymentType.PaymentReference &&
                                    <button className="transfer-button" type="submit" onClick={() => setShowTransferModal(true)}><strong>Transferencia</strong></button>
                                }


                            </Col>

                        </Row> */}


                        {/*   <Row>

                            <Col xs={12} sm={12} md={23} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                                {paymentType && paymentType.PaymentReference &&
                                    <div style={{ minHeight: "190px", border: "solid 2px", borderColor: "#ffd919", width: "80%", margin: "0 auto", padding: "15px", borderRadius: "10px" }}>


                                        <img src={EfectyImg} style={{ width: "80px", cursor: "pointer" }} onClick={() => setShowEfectyModal(true)}></img>

                                        <br />  <br />
                                        Pagar con Efecty
                                    </div>
                                }


                            </Col>

                            <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>




                                {selectedReference && selectedReference.Payments && selectedReference.Payments.map(p => {
                                    if (p.AmountInCents > 0 && p.Enabled) {
                                        return <div key={p.PaymentReference + "_" + p.IdConcept}
                                            style={{ minHeight: "190px", border: "solid 2px", borderColor: "#b0f2ae", width: "80%", margin: "0 auto", padding: "15px", borderRadius: "10px", display: p.IdConcept === paymentType?.IdConcept && p.PaymentReference === paymentType?.PaymentReference ? "" : "none" }}>
                                            <WompiButton data={{
                                                AmountInCents: p.AmountInCents,
                                                PaymentReference: p.PaymentReference,
                                                EncryptedString: p.EncryptedString,
                                            }} />
                                            <br />
                                            Pagar con Wompi<br />
                                            <span style={{ fontSize: "10px", fontWeight: "500" }}>PSE, Nequi, Daviplata, Tarjeta, Corresponsal...</span>
                                        </div>

                                    }
                                })}




                            </Col>

                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                                {paymentType && paymentType.PaymentReference &&
                                    <div style={{ minHeight: "190px", border: "solid 2px", borderColor: "rgb(0 169 235)", width: window.innerWidth > 600 ? "40%" : "80%", margin: "0 auto", padding: "15px", borderRadius: "10px" }}>

                                        <button className="transfer-button" type="submit" onClick={() => setShowTransferModal(true)}></button>
                                        <br />  <br />
                                        Pagar con Transferencia
                                    </div>
                                }
                            </Col>
                        </Row>*/}

                        <Row>


                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                                {paymentType && paymentType.PaymentReference && <div style={{ cursor: "pointer", minHeight: window.innerWidth > 600 ? "210px" : "240px", border: "solid 2px", borderColor: "#da0c81", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", padding: "15px", borderRadius: "10px" }}
                                    onClick={openNequiModal}>


                                    <img src={NequiImg} style={{ width: "80px", cursor: "pointer" }} ></img>


                                    <br />  <br />
                                    Pagar con Nequi
                                    <br />
                                    <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                </div>
                                }
                            </Col>

                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                                {paymentType && paymentType.PaymentReference && <div style={{ cursor: "pointer", minHeight: window.innerWidth > 600 ? "210px" : "240px", border: "solid 2px", borderColor: "#205191", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", padding: "15px", borderRadius: "10px" }}
                                    onClick={() => setShowPSEModal(true)}>


                                    <img src={PSEImg} style={{ width: "80px", cursor: "pointer" }} ></img>


                                    <br />  <br />
                                    Pagar con PSE
                                    <br />
                                    <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                </div>
                                }
                            </Col>

                        </Row>

                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                                {paymentType && paymentType.PaymentReference &&
                                    <div style={{ cursor: "pointer", minHeight: window.innerWidth > 600 ? "210px" : "240px", border: "solid 2px", borderColor: "#ffd919", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", padding: "15px", borderRadius: "10px" }} onClick={() => setShowEfectyModal(true)}>


                                        <img src={EfectyImg} style={{ width: "80px", cursor: "pointer" }} ></img>

                                        <br />  <br />
                                        Pagar con Efecty
                                        <br />
                                        <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica en 1 día</span>
                                    </div>
                                }


                            </Col>



                            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>




                                {selectedReference && selectedReference.Payments && selectedReference.Payments.map(p => {
                                    if (p.AmountInCents > 0 && p.Enabled) {
                                        return <div key={p.PaymentReference + "_" + p.IdConcept}
                                            style={{ position: "relative", height: window.innerWidth > 600 ? "210px" : "240px", border: "solid 2px", borderColor: "#b0f2ae", width: window.innerWidth > 600 ? "80%" : "100%", margin: "0 auto", padding: "15px", borderRadius: "10px", display: p.IdConcept === paymentType?.IdConcept && p.PaymentReference === paymentType?.PaymentReference ? "" : "none" }}>


                                            <div id={p.PaymentReference + "_" + p.IdConcept} onClick={() => submitWompi(p.PaymentReference + "_" + p.IdConcept)}
                                                style={{ cursor: "pointer", width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }}></div>

                                            <WompiButton data={{
                                                AmountInCents: p.AmountInCents,
                                                PaymentReference: p.PaymentReference,
                                                EncryptedString: p.EncryptedString,
                                            }} />
                                            <br />
                                            Pagar con Wompi
                                            <br />
                                            <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica al instante</span>
                                        </div>

                                    }
                                })}




                            </Col>



                        </Row>


                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "10px" }}>
                                {paymentType && paymentType.PaymentReference &&
                                    <div style={{ cursor: "pointer", minHeight: window.innerWidth > 600 ? "210px" : "240px", border: "solid 2px", borderColor: "rgb(0 169 235)", width: window.innerWidth > 600 ? "40%" : "50%", margin: "0 auto", padding: "15px", borderRadius: "10px" }}
                                        onClick={() => setShowTransferModal(true)}>

                                        <button className="transfer-button" type="submit" ></button>
                                        <br />  <br />
                                        Pagar con Transferencia
                                        <br />
                                        <span style={{ fontSize: "10px", fontWeight: "500" }}>Pago se aplica en 2-3 días</span>
                                    </div>
                                }
                            </Col>
                        </Row>



                    </Container>}
                </Modal.Body>


            </Modal>

            <Modal show={showPSEModal} onHide={handlePSEModalClose} dialogClassName="modal-transfer">
                <Modal.Header closeButton>
                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante PSE
                        </span>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Container>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "10px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference?.Reference}</span>
                            </span>
                        </div>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Referencia de pago <span style={{ color: "#00a9eb" }}>#{paymentType?.PaymentReferencePSE}</span>
                            </span>
                        </div>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Concepto <span style={{ color: "#00a9eb" }}>{paymentType?.LabelPayment}</span>
                            </span>
                        </div>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Valor <span style={{ color: "#00a9eb" }}>{paymentType?.LabelAmount}</span>
                            </span>
                        </div>



                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", fontWeight: "bold", marginTop: "20px", }}>
                                Seleccione el banco

                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto" }}>

                                <select className="form-select" style={{ textAlign: "center", borderWidth: "2px" }} value={bank} onChange={(event) => handleChangeBank(event.target.value)}>
                                    <option defaultValue={null}></option>

                                    {bankList.map(c => {

                                        return <option key={c.financialInstitutionCode} value={c.financialInstitutionCode}>{c.financialInstitutionName}</option>
                                    })}

                                </select>

                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                                <button disabled={sending} className="btn btn-mp" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={GetUrlPSE}>Continuar</button>
                            </Col>
                        </Row>


                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                <button className="btn btn-mp-back" style={{ border: "none", backgroundColor: "gray" }} onClick={handlePSEModalClose}>Regresar</button>
                            </Col>
                        </Row>



                    </Container>
                </Modal.Body>


            </Modal>


            <Modal show={showTransferModal} onHide={handleTransferModalClose} dialogClassName="modal-transfer">
                <Modal.Header closeButton>
                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante transferencia
                        </span>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid", borderColor: "black", width: "80%" }}>
                                <b>Banco:</b> Bancolombia<br />
                                <b>Titular:</b> MEDDIPAY S.A.S<br />
                                <b>NIT:</b> 901763399-5<br />
                                <b>Tipo de Cuenta:</b> Ahorros<br />
                                <b>Numero de cuenta:</b> 85900003352<br />

                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                                <p>Una vez realizada la transferencia, por favor, enviar soporte, junto con referencia del crédito a cual esta realizando el pago al siguiente correo electrónico: </p>
                                <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>

                            </Col>
                        </Row>


                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                                <button className="btn btn-mp-back" style={{ border: "none", backgroundColor: "gray" }} onClick={handleTransferModalClose}>Regresar</button>
                            </Col>
                        </Row>



                    </Container>
                </Modal.Body>


            </Modal>



            <Modal show={showEfectyModal} onHide={handleEfectyModalClose} dialogClassName="modal-transfer">
                <Modal.Header closeButton>
                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante Efecty
                        </span>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid", borderColor: "black", width: window.innerWidth > 600 ? "60%" : "80%" }}>
                                <br />
                                <b>Convenio:</b>{window.innerWidth < 600 && <br />} 113754<br /><br />
                                <b>Nombre convenio:</b>{window.innerWidth < 600 && <br />} Meddipay pago facturas<br /><br />
                                <b>Referencia:</b> {window.innerWidth < 600 && <br />} {selectedReference?.Reference}<br /><br />


                            </Col>
                        </Row>

                        {/* <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                            <p>Una vez realizada la transferencia, por favor, enviar soporte, junto con referencia del crédito a cual esta realizando el pago al siguiente correo electrónico: </p>
                            <a target="_blank" rel="noreferrer" href="mailto:hola@meddipay.com">hola@meddipay.com</a>

                        </Col>
                    </Row> */}


                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "50px auto", width: "80%" }}>
                                <button className="btn btn-mp-back" style={{ border: "none", backgroundColor: "gray" }} onClick={handleEfectyModalClose}>Regresar</button>
                            </Col>
                        </Row>



                    </Container>
                </Modal.Body>


            </Modal>


            <Modal show={showNequiModal} onHide={handleNequiModalClose} dialogClassName="modal-transfer">
                <Modal.Header closeButton>
                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>Pagar mediante Nequi
                        </span>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Container>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "10px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Crédito <span style={{ color: "#00a9eb" }}>#{selectedReference?.Reference}</span>
                            </span>
                        </div>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Referencia de pago <span style={{ color: "#00a9eb" }}>#{paymentType?.PaymentReferenceNEQUI}</span>
                            </span>
                        </div>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Concepto <span style={{ color: "#00a9eb" }}>{paymentType?.LabelPayment}</span>
                            </span>
                        </div>

                        <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Valor <span style={{ color: "#00a9eb" }}>{paymentType?.LabelAmount}</span>
                            </span>
                        </div>





                        {!QRvalue && !showAnotherNumberForm && !pendingUPPayment && <>

                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "50px 0px 20px 0px" }}>
                                <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Cómo deseas pagar?
                                </span>
                            </div>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                    <button disabled={sending} className="btn btn-mp" style={{ width: "80%", border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={() => CreateNequiPaymentI("UP")}>Mediante notificación a {selectedReference.MaskedPhone}</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                    <button disabled={sending} className="btn btn-mp" style={{ width: "80%", border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={handleAnotherNumberNequi}>Mediante notificación a otro número</button>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                    <button disabled={sending} className="btn btn-mp" style={{ width: "80%", border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={() => CreateNequiPaymentI("QR")}>Mediante código QR</button>
                                </Col>
                            </Row>
                        </>
                        }
                        {
                            pendingUPPayment && <>
                                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "30px 0px 20px 0px" }}>
                                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>Te hemos enviado una notificación a tu aplicación de Nequi. Por favor sigue los pasos para completar el pago
                                    </span>
                                </div>
                                <Row style={{ textAlign: "center" }}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Spinner animation="border" role="status" style={{ color: "#00a9eb" }} >
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner></Col>
                                </Row>
                                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>Oprime el boton de continuar cuando hayas realizado el pago
                                    </span>
                                </div>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                        <button disabled={sending} className="btn btn-mp" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={getStatusNequi}>Continuar</button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {showAnotherNumberForm &&
                            <>
                                <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "30px 0px 0px 0px" }}>
                                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>Ingresa el número de celular
                                    </span>
                                </div>
                                <Row>

                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                        <input type="text" value={anotherNumber} onChange={event => { setAnotherNumber(event.target.value); setAnotherNumberError(false); }}
                                            style={{ margin: "0 auto" }}
                                            className={"gap-2 mb-4 p-2 border-2 rounded-pill mt-4 form-control reference-input " + (anotherNumberError ? "is-invalid" : "")} />
                                    </Col>

                                </Row>

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto", width: "80%" }}>
                                        <button disabled={sending} className="btn btn-mp" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={() => {
                                            if (typeof Number(anotherNumber) !== 'number') { setAnotherNumberError(true); return };
                                            if (anotherNumber.length < 10) { setAnotherNumberError(true); return };
                                            CreateNequiPaymentI("UP");
                                        }}>Enviar</button>
                                    </Col>
                                </Row>
                            </>



                        }

                        {QRvalue && <>
                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                                <span style={{ fontWeight: "bold", fontSize: "17px" }}>Escanea este código QR mediante tu aplicación de NEQUI para realizar el pago
                                </span>
                            </div>

                            <Row>

                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                    <QRCode value={QRvalue} />
                                </Col>

                            </Row>

                            <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px", margin: "20px auto" }}>
                                <span style={{ fontWeight: "bold", fontSize: "17px" }}>Y oprime el boton de continuar cuando hayas realizado el pago
                                </span>
                            </div>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "0px auto", width: "80%" }}>
                                    <button disabled={sending} className="btn btn-mp" style={{ border: "none", backgroundColor: "rgb(0 169 235)" }} onClick={getStatusNequi}>Continuar</button>
                                </Col>
                            </Row>


                        </>}

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "80px auto", width: "80%" }}>
                                <button className="btn btn-mp-back" style={{ border: "none", backgroundColor: "gray" }} onClick={handleNequiModalClose}>Regresar</button>
                            </Col>
                        </Row>



                    </Container>
                </Modal.Body>


            </Modal>

            <Modal show={showAdvancePaymentModal} onHide={handleAdvancePaymentModalClose} dialogClassName="modal-transfer">
                <Modal.Header closeButton>
                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Que desea hacer con el abono anticipado?
                        </span>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                                Usted a ingresado el valor a pagar de  <b>{paymentType?.LabelAmount}</b> pero ahora necesitamos saber que debemos hacer con este abono anticipado.
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 1 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(1)}>
                                <p style={{ fontWeight: "bold", marginTop: "15px" }}>Reservar para futuras cuotas.</p>
                                <p> El valor excedente se guardara en nuestro sistema y se aplicara a la siguiente cuota tan pronto se emita su facturación.
                                    Nada cambiara en tu plan de pagos.</p>

                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 2 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(2)}>
                                <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir el plazo del Crédito.</p>
                                <p> El plazo de la finalización de tu crédito disminuirá siempre y cuando sea posible, y se conservará el mismo valor de cuota. Recuerde que esta opción no afecta a su Proxima facturación que será el dia <b>{selectedReference.NextBillingDate}</b>.</p>

                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: apOption === 3 ? "#eeffe7" : "unset" }} onClick={() => handleApOption(3)}>
                                <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir la cuota del Crédito</p>
                                <p>El valor de la cuota disminuirá, pero el plazo de tu plan de pagos seguirá siendo el mismo.  Recuerde que esta opción no afecta a su Proxima facturación que será el dia <b>{selectedReference.NextBillingDate}</b>.</p>

                            </Col>
                        </Row>



                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>
                                <button className="btn btn-mp-back" style={{ border: "none", backgroundColor: "gray" }} onClick={handleAdvancePaymentModalClose}>Regresar</button>

                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>

                                <button disabled={sending} className="btn btn-mp" style={{ border: "none" }} onClick={advancePaymentRequest} >Continuar</button>
                            </Col>
                        </Row>



                    </Container>
                </Modal.Body>


            </Modal>


            <Modal show={showOverPaymentModal} onHide={handleOverPaymentModalClose} dialogClassName="modal-transfer">
                <Modal.Header closeButton>
                    <div style={{ textAlign: "center", fontWeight: "bold", width: "100%", fontSize: "20px" }}>
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>¿Que deseas hacer con el abono anticipado?
                        </span>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: "80%" }}>
                                Por favor selecciona que deseas realizar con el pago anticipado de {FPayment !== 2 ? <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100)}</b>
                                    :
                                    <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0))}</b>}
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 1 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(1)}>
                                <p style={{ fontWeight: "bold", marginTop: "15px" }}>Reservar para futuras cuotas.</p>
                                <p>  El valor del pago anticipado se guardara en nuestro sistema y se aplicara a la siguiente cuota tan pronto se emita su facturación. Nada cambiara en tu plan de pagos.
                                    Afectara primero a tu facturación mas cercana que es el dia <b>{selectedReference?.NextBillingDate}</b></p>

                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 2 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(2)}>
                                <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir el plazo del Crédito.</p>
                                <p> El plazo de finalización de tu crédito disminuirá siempre y cuando sea posible, y se conservará el mismo valor de cuota. Recuerda que esto no afecta a la facturación del dia <b>{selectedReference?.NextBillingDate}</b></p>

                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", border: "solid 1px", borderColor: "black", width: "80%", borderRadius: "10px", cursor: "pointer", backgroundColor: opOption === 3 ? "#eeffe7" : "unset" }} onClick={() => handleOpOption(3)}>
                                <p style={{ fontWeight: "bold", marginTop: "15px" }}>Abonar para reducir la cuota del Crédito</p>
                                <p>El valor de la cuota disminuirá, pero el plazo de tu plan de pagos seguirá siendo el mismo. Recuerda que esto no afecta a la facturación del dia <b>{selectedReference?.NextBillingDate}</b></p>

                            </Col>
                        </Row>



                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>
                                <button className="btn btn-mp-back" style={{ border: "none", backgroundColor: "gray" }} onClick={handleOverPaymentModalClose}>Regresar</button>

                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ textAlign: "center", margin: "20px auto" }}>

                                <button disabled={sending} className="btn btn-mp" style={{ border: "none" }} onClick={overPaymentRequest}>Continuar</button>
                            </Col>
                        </Row>



                    </Container>
                </Modal.Body>


            </Modal>


            <Modal show={showPendingPaymentModal} onHide={handlePendingPaymentModalClose} dialogClassName="modal-transfer">


                <Modal.Body>
                    <Container style={{ minHeight: window.innerWidth < 600 ? "100vh" : "unset" }}>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ fontSize: "20px", textAlign: "center", margin: "20px auto", width: window.innerWidth < 600 ? "100%" : "80%", fontWeight: "bold" }}>

                                <img className="img-fluid" style={{ width: "100px" }}
                                    src={PPAlert}
                                    //src="assets/images/img/PPAlert.svg"
                                    alt="paymentA" />
                                <br />
                                <br />
                                Cuentas con una cuota proxima a vencer
                            </Col>
                        </Row>



                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto", width: window.innerWidth < 600 ? "100%" : "80%" }} >
                                <p style={{ marginTop: "15px", fontSize: "15px" }}>

                                    Actualmente cuentas con una cuota proxima a vencer y por lo tanto pendiente por pagar por un valor de <b>{selectedMinimumPayment?.LabelAmount}</b> y fecha limite de pago el <b>{selectedMinimumPayment.LabelLimitDate}</b>.
                                    Te recomendamos usar el valor introducido manualmente de <b>{paymentType?.LabelAmount}</b> para cubrir esta cuota.
                                    <br /><br />
                                    {Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "")) > (selectedMinimumPayment?.AmountInCents / 100) && <span>El sobrante de los <b>$ {moneyLocale.format((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100)}</b> podrás usarlo para abonar a capital.</span>}
                                    {Number(paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "")) < (selectedMinimumPayment?.AmountInCents / 100) && <span>Recuerda que después de este pago, aun deberas pagar <b>$ {moneyLocale.format(((paymentType?.LabelAmount ? paymentType?.LabelAmount.replaceAll("$", "").replaceAll(".", "") : 0) - (selectedMinimumPayment?.AmountInCents) / 100) * -1)}</b> antes de la fecha limite de pago para seguir disponiendo de tu cupo y no incurrir en intereses moratorios.</span>}

                                </p>

                            </Col>
                        </Row>



                        <Row>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto" }}>

                                <button className="btn btn-mp" style={{ border: "none" }} onClick={() => handlePendingPayment(1)}>
                                    Pagar cuota
                                </button>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "20px auto" }}>
                                <button className="btn btn-mp-back" style={{ border: "none", backgroundColor: "gray" }} onClick={handlePendingPaymentModalClose}>Regresar</button>

                            </Col>
                        </Row>



                    </Container>

                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center", margin: "10px auto" }}  >

                                <Accordion flush style={{ margin: "0 auto", textAlign: "center" }} className="accordion-movements">



                                    <Card key="0" style={{ border: "none", boxShadow: "none", marginBottom: "10px", maxWidth: "100%", width: "100%" }}>
                                        <Card.Header style={{ padding: "0", border: "none" }}>
                                            <CustomToggleOtherOPS eventKey="0">
                                                <div style={{ padding: "15px", fontWeight: "bold", textAlign: "center", width: "100%", color: "#00a9eb", textDecoration: "underline" }}>Otras opciones</div>

                                            </CustomToggleOtherOPS>
                                        </Card.Header>

                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body style={{ border: "none", padding: "5px", paddingTop: "20px", paddingBottom: "10px" }}>
                                                <Container>


                                                    <button className="btn btn-mp-back" style={{ borderRadius: "15px", border: "solid 2px red", backgroundColor: "white", color: "black" }} onClick={() => handlePendingPayment(2)}>Abonar todo el valor ({paymentType?.LabelAmount}) a capital</button>

                                                    {/* <p style={{ cursor: "pointer", fontWeight: "bold", marginTop: "15px", fontSize: "15px", color: "#00a9eb", textDecoration: "underline" }} onClick={() => handlePendingPayment(2)}>Abonar a capital</p> */}

                                                </Container>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                </Accordion>



                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>


            </Modal>



            <Modal show={showPdfContratoModal} onHide={handleClosePdfContratoModal} dialogClassName="modal-aut">
                <Modal.Header closeButton>

                </Modal.Header>

                <Modal.Body>
                    <PdfViewer base64={pdfContrato.pdf} id="adobe-contrato" fileName={pdfContrato.name} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={downloadPdf}>Descargar</Button>
                    <Button variant="secondary" onClick={handleClosePdfContratoModal}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>

    );
}

export default Dashboard;