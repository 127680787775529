import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useCreditRequestAuth } from '../Context/useCreditRequestAuth';

export const ProtectedRoutesCreditRequest = ({ children }) => {
    const { cookies, getStorage } = useCreditRequestAuth();
    const location = useLocation();

    let request = getStorage("request")
    let creditRequestToken = getStorage("creditRequestToken")
    let expiredTime = getStorage("expiredTime")


    if (!creditRequestToken || !request || new Date().getTime() > expiredTime) {
        return <Navigate to="/" exact />
    }

    if (request.state === 0 && location.pathname !== "/") {
        return <Navigate to="/" exact />
    }

    if (request.state === 1 && location.pathname !== "/Enrollment") {
        return <Navigate to="/Enrollment" exact />
    }

    if (request.state === 2 && location.pathname !== "/DigitalConfirmation") {
        return <Navigate to="/DigitalConfirmation" exact />
    }

    if (request.state === 3 && !request.OTP && location.pathname !== "/DigitalConfirmation") {
        return <Navigate to="/DigitalConfirmation" exact />
    }

    if (request.state === 3 && request.OTP && location.pathname !== "/DigitalConfirmationLink") {
        return <Navigate to="/DigitalConfirmationLink" exact />
    }

    if (request.state === 4 && location.pathname !== "/Profile") {
        return <Navigate to="/Profile" exact />
    }

    if (request.state === 5 && location.pathname !== "/Verification") {
        return <Navigate to="/Verification" exact />
    }

    if (request.state === 6 && location.pathname !== "/SelfieVerificationLink") {
        return <Navigate to="/SelfieVerificationLink" exact />
    }

    if (request.state === 7 && location.pathname !== "/SelfieVerification") {
        return <Navigate to="/SelfieVerification" exact />
    }

    if (request.state === 8 && location.pathname !== "/ManualValidation") {
        return <Navigate to="/ManualValidation" exact />
    }

    const paymentPaths = ["/paymentResultPSE", "/paymentResult","/paymentResultNequi"];
    if (request.state === 9 && location.pathname !== "/Dashboard") {
        if (!paymentPaths.includes(location.pathname))
            return <Navigate to="/Dashboard" exact />
    }

    if (request.state === 10 && location.pathname !== "/ApplicationRequest") {
        return <Navigate to="/ApplicationRequest" exact />
    }

    // if (request.state === 11 && location.pathname !== "/CustomerSurvey") {
    //     return <Navigate to="/CustomerSurvey" exact />
    // }

    if (request.state === 12 && location.pathname !== "/eSign") {
        return <Navigate to="/eSign" exact />
    }

    if (request.state === 13 && location.pathname !== "/QuestionsVerification") {
        return <Navigate to="/QuestionsVerification" exact />
    }

    return creditRequestToken
        && request
        && new Date().getTime() < expiredTime
        //&& Object.keys(request).length !== 0
        // && Object.keys(enumsCustomer).length !== 0
        ? (children ? children : <Outlet />) : <Navigate to="/" exact />
};